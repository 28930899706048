import { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  Box,
  Dialog,
  DialogContentText,
  DialogTitle,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Button,
  DialogContent,
  DialogActions,
  TextField,
  Paper,
} from "@mui/material";
import FancyPaper from "../../components/FancyPaper";
import ErrorAlert from "../../components/utils/ErrorAlert";
import { useData } from "../../hooks/useData";
import { useApp } from "../../hooks/useApp";
import { useForm, Controller } from "react-hook-form";
import Step1Agrupacion from "./Step1Agrupacion";
import { getRequestUserMatriz } from "../../services/users/UserMatrizService";
import {
  getConstitutionTime,
  getRequestAgrupacion,
} from "../../services/agrupaciones/AgrupacionService";
import {
  createProfileMatriz,
  getProfileMatriz,
} from "../../services/profile/ProfileService";
import Step2ValidateClient from "./Step2ValidateClient";
import Step3Result from "./Step3Result";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RiskMatrixTabs = () => {
  const {
    companyIndexDB,
    subCompanyData,
    handleFetchFactors,
    factors,
    handleLocalCompaniesAndSubcompanies,
  } = useData();
  const { setLoading, setErrorMsg, riskData, setSuccessMsg } = useApp();
  const [valueStep, setValueStep] = useState<number>(0);
  const [dataConstitutionTime, setDataConstitutionTime] = useState<any>(null);
  const [showInitModal, setShowInitModal] = useState<boolean>(false);
  const [dataUserSearched, setDataUserSearched] = useState<any>(null);
  const [dataUserSearchedShow, setDataUserSearchedShow] = useState<any>(null);
  const [optionsTipoPersona, setOptionsTipoPersona] = useState<any>([]);

  const [expanded, setExpanded] = useState<boolean>(false);
  const [expendedTab1, setExpendedTab1] = useState<boolean>(true);
  const [expendedTab2, setExpendedTab2] = useState<boolean>(false);
  const [factorsAndOptions, setFactorsAndOptions] = useState<any>(null);
  const [listShow, setListShow] = useState<{ label: string }[]>([]);
  const [matrixGroups, setMatrixGroups] = useState<any>(null);
  const [branchData, setBranchData] = useState<any>(null);
  const [optionsSelected, setOptionsSelected] = useState<any>([]);
  const [optionsEjecutivo, setOptionsEjecutivo] = useState<any>(null);
  const [isPep, setIsPep] = useState<any>([]);
  const [comments, setComments] = useState<string>("");
  const [commentsFactor, setCommentsFactor] = useState<string>("");

  {
    /**Estados de ValidateClient */
  }
  const [listSearch, setListSearch] = useState<any>(null);
  const [listSearchBlackList, setListSearchBlackList] = useState<any>(null);
  const [listSearchCRCResult, setListSearchCRCResult] = useState<any>(null);
  const [listSearchJCEResult, setListSearchJCEResult] = useState<any>(null);

  const [runUploadDataInForm, setRunUploadDataInForm] =
    useState<boolean>(false);

  const {
    register,
    formState: { errors },
    control,
    watch,
    setValue,
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueStep(newValue);
  };

  useEffect(() => {
    handleLocalCompaniesAndSubcompanies &&
      handleLocalCompaniesAndSubcompanies();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!companyIndexDB?.company?.id) return;

      const subCompanyId =
        subCompanyData !== null ? companyIndexDB?.subCompany?.id : "";
      handleFetchFactors &&
        handleFetchFactors(0, companyIndexDB.company.id, subCompanyId, 100);
    };

    fetchData();
  }, [companyIndexDB, subCompanyData]);

  return (
    <FancyPaper pagetitle="Mantenimiento / Matriz de riesgo">
      {factors === null || factors.length === 0 ? (
        <ErrorAlert
          message={
            !companyIndexDB?.company?.id
              ? "Debe seleccionar primero compañía y subcompañía"
              : "La compañía tiene subcompañías asociadas, debe seleccionar una de ellas"
          }
        />
      ) : (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={valueStep}
              onChange={handleChange}
              aria-label="tabs risk matrix"
            >
              <Tab label="Matriz de riesgo" {...a11yProps(0)} />
              <Tab
                label="Lexis Nexis"
                {...a11yProps(1)}
                disabled={dataUserSearchedShow === null}
              />
              <Tab
                label="Resultados"
                {...a11yProps(2)}
                disabled={dataUserSearchedShow === null}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={valueStep} index={0}>
            <Step1Agrupacion
              showInitModal={showInitModal}
              setShowInitModal={setShowInitModal}
              dataUserSearched={dataUserSearched}
              setDataUserSearched={setDataUserSearched}
              dataUserSearchedShow={dataUserSearchedShow}
              setDataUserSearchedShow={setDataUserSearchedShow}
              expanded={expanded}
              setExpanded={setExpanded}
              expendedTab1={expendedTab1}
              setExpendedTab1={setExpendedTab1}
              expendedTab2={expendedTab2}
              setExpendedTab2={setExpendedTab2}
              factorsAndOptions={factorsAndOptions}
              setFactorsAndOptions={setFactorsAndOptions}
              listShow={listShow}
              setListShow={setListShow}
              matrixGroups={matrixGroups}
              setMatrixGroups={setMatrixGroups}
              branchData={branchData}
              setBranchData={setBranchData}
              optionsSelected={optionsSelected}
              setOptionsSelected={setOptionsSelected}
              optionsTipoPersona={optionsTipoPersona}
              setOptionsTipoPersona={setOptionsTipoPersona}
              optionsEjecutivo={optionsEjecutivo}
              setOptionsEjecutivo={setOptionsEjecutivo}
              dataConstitutionTime={dataConstitutionTime}
              setDataConstitutionTime={setDataConstitutionTime}
              setValueStep={setValueStep}
              isPep={isPep}
              setIsPep={setIsPep}
              listSearch={listSearch}
              comments={comments}
              setComments={setComments}
              commentsFactor={commentsFactor}
              setCommentsFactor={setCommentsFactor}
              setListSearchJCEResult={setListSearchJCEResult}
            />
          </CustomTabPanel>
          <CustomTabPanel value={valueStep} index={1}>
            <Step2ValidateClient
              dataClient={dataUserSearchedShow}
              factorsAndOptions={factorsAndOptions}
              listSearch={listSearch}
              setListSearch={setListSearch}
              listSearchBlackList={listSearchBlackList}
              setListSearchBlackList={setListSearchBlackList}
              listSearchCRCResult={listSearchCRCResult}
              setListSearchCRCResult={setListSearchCRCResult}
              listSearchJCEResult={listSearchJCEResult}
              setListSearchJCEResult={setListSearchJCEResult}
            />
          </CustomTabPanel>
          <CustomTabPanel value={valueStep} index={2}>
            <Step3Result
              dataClient={dataUserSearchedShow}
              optionsSelected={optionsSelected}
              isPep={isPep}
            />
          </CustomTabPanel>
        </Box>
      )}
    </FancyPaper>
  );
};

export default RiskMatrixTabs;
